<template>
  <!-- <div class="text-center" style="background-color:red"> -->
    <!-- <div> -->
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    style="paddingTop:40px; margin:auto"
  >
    <div class="text-center">
      <h2 :class="ClaseTitulo">{{TituloPantalla}}</h2>
      <h4 class="grey--text text--darken-1">Es necesario conocer la contraseña actual</h4>
    </div>

    <br>
      <!-- <hr style="marginLeft:-2000px; marginRight:-2000px"> -->
      <hr style="margin-left:10px; margin-right:10px">
    <br>

    <div class="text-center">
      <v-text-field
        outlined=""
        v-model="passwordActual"
        :rules="passwordActualRules"
        label="Contraseña actual"
        required
        style="width:300px; margin-left:auto; margin-right:auto"
        prepend-icon="mdi-lock"
        :disabled = "passwordActualValidada"
        :append-icon="showPasswordActual ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPasswordActual ? 'text' : 'password'"
        @click:append="showPasswordActual = !showPasswordActual"
      ></v-text-field>

      <v-btn
        color="primary"
        @click="comprobarPasswordActual"
        style="width: 200px"
        class = "mb-5"
        :disabled = "passwordActualValidada"
      >
        Comprobar
      </v-btn>

      <p class="text-center body-2 font-weight-bold grey--text text--darken-3" style="width:300px; margin-left:auto; margin-right:auto">
        Seguridad de la contraseña:
      </p>

      <p class="text-center body-2 mb-6 grey--text text--darken-3" style="width:300px; margin-left:auto; margin-right:auto">
        Usa al menos 6 caracteres. No uses una 
        contraseña de otro sitio ni algo demasiado 
        obvio, como tu nombre o el nombre de tu hijo(a).
      </p>

      <v-text-field
        outlined=""
        v-model="passwordNueva"
        :rules="[rules.required, rules.min]"
        label="Nueva contraseña"
        style="width:300px; margin-left:auto; margin-right:auto"
        prepend-icon="mdi-lock"
        :disabled = "!passwordActualValidada"
        :append-icon="showPasswordNueva ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPasswordNueva ? 'text' : 'password'"
        @click:append="showPasswordNueva = !showPasswordNueva"

      ></v-text-field>

      <v-text-field
        outlined=""
        v-model="passwordConfirmacion"
        :rules="passMatchRules"
        label="Confirmación de contraseña"
        style="width:300px; margin-left:auto; margin-right:auto"
        prepend-icon="mdi-lock"
        :disabled = "!passwordActualValidada"
        :append-icon="showPasswordConfirmacion ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPasswordConfirmacion ? 'text' : 'password'"
        @click:append="showPasswordConfirmacion = !showPasswordConfirmacion"
      ></v-text-field>

      <v-btn
        color="success"
        @click="validate"
        style="width:230px"
        :disabled = "!passwordActualValidada"
      >
        Cambiar la contraseña
      </v-btn>
    </div>

  </v-form>
</template>

<script>
  import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  export default {
    computed:{
      ...mapState(['empId', 'perId', 'urlRaiz', 'per', 'emp', 'exigiendoCambioPassword']),
      passMatchRules() {
        return [
          v => !!v || 'La contraseña es obligatoria',
          v => v.length >= 6 || 'La contraseña debe tener 6 caracteres como mínimo',
          () => (this.passwordConfirmacion === this.passwordNueva) || `La nueva contraseña y su confirmación no coinciden`,
        ]
      }
    },
    data: () => ({
      TituloPantalla: "Gestión de contraseña",
      ClaseTitulo : "",
      showPasswordActual: false,
      showPasswordNueva: false,
      showPasswordConfirmacion: false,
      passwordActualValidada: false,
      valid: true,
      passwordActual: '',
      passwordActualRules: [
        v => !!v || 'La contraseña actual es obligatoria',
      ],
      passwordNueva: '',
      passwordNuevaRules: [
        v => !!v || 'La contraseña nueva es obligatoria',
      ],
      RequiredRules: [
        v => !!v || 'La contraseña es obligatoria',
      ],
      LengthRules: [
        v => v.length >= 6 || 'La contraseña debe tener 6 caracteres como mínimo',
      ],
      rules: {
        required: value => !!value || 'La contraseña es obligatoria',
        min: v => v.length >= 6 || 'La contraseña debe tener 6 caracteres como mínimo'
      },      
      passwordConfirmacion: '',
      passwordConfirmacionRules: [
        v => !!v || 'La confirmación de contraseña es obligatoria',
      ],
      empresa: {},
      tituloPrincipal: "",
      tituloSecundario: ""
    }),
    methods: {
      ...mapMutations(['exigiendoCambioPasswordSet']),
      comprobarPasswordActual() {
        var loginObj = { UserName: this.$store.state.usuario, Password: this.passwordActual, LoginUse: "CHANGE_PASSWORD" };
        var loginJson = JSON.stringify(loginObj);

         axios({ method: "POST", "url": this.urlRaiz + "/api/login/authenticate", "data": loginJson, "headers": { "content-type": "application/json" } })
            .then(result => {
              if (result.status >= 200 && result.status <= 299){
                this.passwordActualValidada = true;
              }
            })
            .catch(error => {
              if (error.response.status == 401){
                alert("Contraseña incorrecta");
              }
              else{
                alert(error);
              }
            })
      },
      validate () {
        this.$refs.form.validate()
        if (this.valid)
        {
            this.enviar();
        }
      },
      enviar () {
        var loginObj = { UserName: this.$store.state.usuario, Password: this.passwordNueva, LoginUse: "CHANGE_PASSWORD" };
        var loginJson = JSON.stringify(loginObj);

         axios({ method: "POST", "url": this.urlRaiz + "/api/login/changepassword", "data": loginJson, "headers": { "content-type": "application/json" } })
            .then(result => {
              alert(result.statusText + ". Contraseña cambiada correctamente");

                if (this.exigiendoCambioPassword) {
                  this.$store.dispatch('AsignarUsuario').then (() => {
                      this.$store.dispatch('AsignarUsuarioAux').then(() => {
                        this.loadMsjCat();
                        this.ActualizarMensajes();
                        // setInterval(this.ActualizarMensajes, 60000);
                    }) ;
                  })
                  
                  // this.exigiendoCambioPasswordSet(false);
                  // this.$router.push('/')
                }
            })
            .catch(error => {
              if (error.response.status == 401){
                alert("Usuario o contraseña incorrecto");
              }
              else{
                alert(error);
              }
            })
      }
    },
    mounted(){
      if (this.exigiendoCambioPassword) {
        this.TituloPantalla = "¡ATENCIÓN! DEBE CAMBIAR LA CONTRASEÑA INICIAL"
        this.ClaseTitulo = "red--text text--darken-4"
      }
      else {
        this.TituloPantalla = "Cambio de contraseña"
        this.ClaseTitulo = "black--text"
      }
    }
  }
</script>